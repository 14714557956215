import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Carbon Vanilla library</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Install</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Getting started</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Troubleshooting</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "carbon-vanilla-library"
    }}>{`Carbon Vanilla library`}</h2>
    <p>{`The library provides front-end developers & engineers a collection of reusable HTML and SCSS partials to build websites and user interfaces. Adopting the library enables developers to use consistent markup, styles, and behavior in prototype and production work.`}</p>
    <h2 {...{
      "id": "install"
    }}>{`Install`}</h2>
    <h4 {...{
      "id": "using-npm"
    }}>{`Using npm:`}</h4>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ npm install --save carbon-components
`}</code></pre>
    <h4 {...{
      "id": "if-you-prefer-yarn"
    }}>{`If you prefer `}<a parentName="h4" {...{
        "href": "https://yarnpkg.com/en/"
      }}>{`Yarn`}</a>{`:`}</h4>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn add carbon-components
`}</code></pre>
    <p><em parentName="p">{`NB: March 29, 2019 - Please use the @next tag to install Carbon v10 components. e.g. `}<inlineCode parentName="em">{`npm i carbon-components@next`}</inlineCode>{`.`}</em></p>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting started`}</h2>
    <AnchorLinks small mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">What's included</AnchorLink>
      <AnchorLink mdxType="AnchorLink">CDN</AnchorLink>
      <AnchorLink mdxType="AnchorLink">CodePen</AnchorLink>
      <AnchorLink mdxType="AnchorLink">SCSS</AnchorLink>
      <AnchorLink mdxType="AnchorLink">JavaScript</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Polyfills for older browsers</AnchorLink>
    </AnchorLinks>
    <h3 {...{
      "id": "whats-included"
    }}>{`What’s included`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`carbon-components/
├── html
├── css
│   ├── carbon-components.css
│   └── carbon-components.min.css
├── scripts
│   ├── carbon-components.js
│   └── carbon-components.min.js
├── scss
│   └── components
│       └── modal
│           └── _modal.scss
├── umd
│   └── index.js
├── es
│   └── index.js
└── src (Deprecated and subject to breaking changes, please use es/umd instead)
`}</code></pre>
    <h3 {...{
      "id": "cdn"
    }}>{`CDN`}</h3>
    <p>{`Use unpkg for easy access to our built static files. This is great for prototyping and trying `}<inlineCode parentName="p">{`carbon-components`}</inlineCode>{` without installing anything. See all files from `}<inlineCode parentName="p">{`carbon-components`}</inlineCode>{` available on `}<a parentName="p" {...{
        "href": "https://unpkg.com/carbon-components/"
      }}>{`unpkg CDN`}</a>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Scripts`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`URL`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CSS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://unpkg.com/carbon-components/css/carbon-components.min.css"
            }}>{`https://unpkg.com/carbon-components/css/carbon-components.min.css`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ES5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://unpkg.com/carbon-components/scripts/carbon-components.min.js"
            }}>{`https://unpkg.com/carbon-components/scripts/carbon-components.min.js`}</a></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "codepen"
    }}>{`CodePen`}</h3>
    <p>{`We have individual `}<a parentName="p" {...{
        "href": "https://codepen.io/collection/XqRbEz/"
      }}>{`CodePens`}</a>{` for all of our components which you can easily fork and play around with.`}</p>
    <h3 {...{
      "id": "scss"
    }}>{`SCSS`}</h3>
    <p>{`Using the Carbon Sass files infers usage of the SCSS pre-processor.
All Sass files use the `}<inlineCode parentName="p">{`*.scss`}</inlineCode>{` file extension.`}</p>
    <p>{`For transpiling Sass code, use `}<inlineCode parentName="p">{`node-sass`}</inlineCode>{` based Sass compilers, for example, WebPack `}<inlineCode parentName="p">{`sass-loader`}</inlineCode>{` or `}<inlineCode parentName="p">{`gulp-sass`}</inlineCode>{`.`}</p>
    <p>{`If you’re starting a new project without a boilerplate,
you need to know about a few things to get started.`}</p>
    <h4 {...{
      "id": "autoprefixer"
    }}>{`Autoprefixer`}</h4>
    <p>{`Make sure your build process uses `}<a parentName="p" {...{
        "href": "https://github.com/postcss/autoprefixer"
      }}>{`autoprefixer`}</a>{` to ensure vendor prefixes are automatically added to your output CSS.`}</p>
    <h4 {...{
      "id": "default-body-styles"
    }}>{`Default body styles`}</h4>
    <p>{`CSS is automatically applied to `}<inlineCode parentName="p">{`<body>`}</inlineCode>{` element, which comes from `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-components/blob/master/src/globals/scss/_css--body.scss"
      }}>{`_`}{`css—body.scss`}</a>{`. These styles are meant to cascade down to everything in `}<inlineCode parentName="p">{`<body>`}</inlineCode>{` to set common styles shared across all components.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`body {
  @include reset;
  @include type-style('body-short-01');
  color: $text-01;
  background-color: $ui-background;
  line-height: 1;
}
`}</code></pre>
    <h4 {...{
      "id": "icons"
    }}>{`Icons`}</h4>
    <p>{`A lot of components depend on SVG icons from `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-icons"
      }}>{`carbon-icons`}</a>{`. Read the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-icons/blob/master/docs/usage.md"
      }}>{`docs`}</a>{` for details on how to use them.`}</p>
    <h4 {...{
      "id": "global-scss-variables"
    }}>{`Global SCSS variables`}</h4>
    <p>{`These variables are used to configure which parts of the SCSS get compiled, where each variable controls a SCSS file of the same name. All variables are set to `}<inlineCode parentName="p">{`true`}</inlineCode>{` by default.`}</p>
    <p>{`For example:`}</p>
    <ul>
      <li parentName="ul">{`When you set `}<inlineCode parentName="li">{`$css--reset: true`}</inlineCode>{`, then the contents of `}<a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-components/blob/master/src/globals/scss/_css--reset.scss"
        }}>{`_`}{`css—reset.scss`}</a>{` will be part of your output CSS.`}</li>
      <li parentName="ul">{`When you set `}<inlineCode parentName="li">{`$css--reset: false`}</inlineCode>{`, then nothing gets included from that SCSS file.`}</li>
      <li parentName="ul">{`When the variable is not declared at all, then nothing gets included from that SCSS file.`}</li>
    </ul>
    <p>{`The same rules apply to all the following variables:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// In styles.scss:
// These are the default settings.
$css--font-face: true !default;
$css--helpers: true !default;
$css--body: true !default;
$css--use-layer: true !default;
$css--reset: true !default;
$css--typography: true !default;
$css--plex: true !default;
`}</code></pre>
    <p>{`These flags are set for you by default when you `}<inlineCode parentName="p">{`@import`}</inlineCode>{` the `}<inlineCode parentName="p">{`styles.scss`}</inlineCode>{` file.
You can override these default settings by redeclaring the variables.`}</p>
    <h4 {...{
      "id": "importing-scss-files"
    }}>{`Importing SCSS files`}</h4>
    <p>{`To add a component style to your build, simply import the component directly. Importing a component this way will bring in any dependencies that component has as well. The import system removes duplicate dependencies, so shared dependencies between components will not create extra CSS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`@import 'node_modules/carbon-components/scss/components/card/card';
`}</code></pre>
    <h4 {...{
      "id": "namespacing"
    }}>{`Namespacing`}</h4>
    <p>{`Carbon Components are built to be included individually and not clobber global styles - all `}<inlineCode parentName="p">{`class`}</inlineCode>{` attributes are prefixed by the `}<inlineCode parentName="p">{`bx--`}</inlineCode>{` moniker. You can specify your own prefix by changing the SCSS variable `}<inlineCode parentName="p">{`$prefix`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "javascript"
    }}>{`JavaScript`}</h3>
    <p>{`Carbon Component has component JavaScript classes, each of which correspond to a component found in `}<a parentName="p" {...{
        "href": "../../components/overview"
      }}>{`our components page`}</a>{`. The first steps to work with component JavaScript classes are the following:`}</p>
    <h4 {...{
      "id": "1-getting-component-javascript-class-reference"
    }}>{`1. Getting component JavaScript class reference`}</h4>
    <br />
    <p><strong parentName="p">{`Using a module bundler:`}</strong>{` We recommend using ECMAScript module along with your module bundler toolchain to do so. Using a module bundler will bring in only the component code your application needs, creating an optimized build for production. Carbon Components ships with a ECMAScript module build as well as UMD build for each component, for use with webpack or Rollup.`}</p>
    <p>{`After you’ve installed the components through `}<inlineCode parentName="p">{`npm`}</inlineCode>{`, you can grab a component JavaScript class reference by something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Modal } from 'carbon-components';
`}</code></pre>
    <p><strong parentName="p">{`Using pre-built bundle:`}</strong>{` Users can also opt to use the pre-built `}<inlineCode parentName="p">{`carbon-components.js`}</inlineCode>{` file directly, like below. We recommend that most users do `}<em parentName="p">{`not`}</em>{` use this file, as it includes components your application may or may not actually be using.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html>
  <body>
    <!-- Put HTML snippets of components here... -->
    <script src="node_modules/carbon-components/scripts/carbon-components.min.js"></script>
  </body>
</html>
`}</code></pre>
    <p>{`Once you load `}<inlineCode parentName="p">{`carbon-components.js`}</inlineCode>{` via `}<inlineCode parentName="p">{`<script>`}</inlineCode>{` tag, you can grab a component JavaScript class reference by something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var Modal = CarbonComponents.Modal;
`}</code></pre>
    <p><em parentName="p">{`Note: By default, pre-built bundle automatically instantiates all components on the page when`}</em>{` `}<inlineCode parentName="p">{`DOMContentLoaded`}</inlineCode>{` `}<em parentName="p">{`event on the page fires. In case you don’t want that behavior, you can set`}</em>{` `}<inlineCode parentName="p">{`CarbonComponents.settings.disableAutoInit = true`}</inlineCode>{` `}<em parentName="p">{`right after`}</em>{` `}<inlineCode parentName="p">{`carbon-components.js`}</inlineCode>{` `}<em parentName="p">{`is loaded.`}</em></p>
    <p><em parentName="p">{`Caveat: Don’t use pre-built`}</em>{` `}<inlineCode parentName="p">{`carbon-components.js`}</inlineCode>{` `}<em parentName="p">{`if you are importing components via ECMAScript module syntax. Doing so will cause Carbon code loaded twice, often competing each other.`}</em></p>
    <h4 {...{
      "id": "2-instantiating-component-class-on-dom-nodes"
    }}>{`2. Instantiating component class on DOM nodes`}</h4>
    <p>{`Once you have a `}<a parentName="p" {...{
        "href": "#1-getting-component-javascript-class-reference"
      }}>{`component JavaScript class reference`}</a>{`, you can instantiate it on a DOM node with the `}<inlineCode parentName="p">{`.create()`}</inlineCode>{` API.`}</p>
    <p>{`For example, if you have the following HTML for modal:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div data-modal id="modal-nofooter" class="bx--modal" tabindex="-1">
  <div class="bx--modal-container">...</div>
</div>
`}</code></pre>
    <p>{`You can create and get the instance by:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const modalElement = document.getElementById('modal-nofooter');
const modalInstance = Modal.create(modalElement);
`}</code></pre>
    <p><em parentName="p">{`Note: The DOM element to instantiate components on typically has a `}<inlineCode parentName="em">{`data-componentname`}</inlineCode>{` attribute, e.g. `}<inlineCode parentName="em">{`data-modal`}</inlineCode>{` for modal.`}</em></p>
    <p>{`Instantiating a component basically does two things:`}</p>
    <ul>
      <li parentName="ul">{`Hooks several event handlers on some DOM elements inside (in above example, ones in `}<inlineCode parentName="li">{`modalElement`}</inlineCode>{`, e.g. close button)`}</li>
      <li parentName="ul">{`Allows you to access public methods (found in `}<a parentName="li" {...{
          "href": "../../components/overview"
        }}>{`our components page`}</a>{`, e.g. `}<a parentName="li" {...{
          "href": "/components/modal/code#public-methods"
        }}>{`here`}</a>{` for modal) via the instance reference (`}<inlineCode parentName="li">{`modalInstance.show()`}</inlineCode>{`, etc. in above example)`}</li>
    </ul>
    <h4 {...{
      "id": "higher-level-component-instantiation-api"
    }}>{`Higher-level component instantiation API`}</h4>
    <p>{`While `}<inlineCode parentName="p">{`.create()`}</inlineCode>{` API gives you the full control of component instantiation, there is a higher-level API for instantiating components, which is, `}<inlineCode parentName="p">{`.init(rootElement)`}</inlineCode>{`. It instantiates all components with `}<inlineCode parentName="p">{`data-componentname`}</inlineCode>{` attribute (e.g. `}<inlineCode parentName="p">{`data-modal`}</inlineCode>{` for modal) inside the given `}<inlineCode parentName="p">{`rootElement`}</inlineCode>{`. If `}<inlineCode parentName="p">{`rootElement`}</inlineCode>{` is omitted, `}<inlineCode parentName="p">{`document`}</inlineCode>{` is used.`}</p>
    <p><em parentName="p">{`Note:`}</em>{` `}<inlineCode parentName="p">{`.init()`}</inlineCode>{` `}<em parentName="p">{`API does not take care of DOM elements that hasn’t been available at the time it’s called.
If the JavaScript framework you are using creates DOM elements dynamically,
follow the instructions in the next section instead of using`}</em>{` `}<inlineCode parentName="p">{`.init()`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "wrapping-a-component-with-javascript-framework-of-your-choice"
    }}>{`Wrapping a component with JavaScript framework of your choice`}</h4>
    <p>{`Many JavaScript frameworks have a mechanism to dynamically create/destroy DOM elements, for example, upon change in array.
This often makes it unclear when the DOM element to instantiate Carbon component on is available, which often depends on the JavaScript framework you use.`}</p>
    <p>{`Also when DOM elements that Carbon components have been instantiated on are being destroyed, the Carbon component instances should be released so that e.g. there are no zombie event handlers.`}</p>
    <p>{`The easiest way to hook on the creation/destroy of DOM elements is defining a “wrapping component”, with the JavaScript framework of your choice. Here’s an example using Web Components’ `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Web_Components/Custom_Elements"
      }}>{`Custom Elements v1 spec`}</a>{`, but the notion of components, along with the lifecycle callbacks, are commonly found in many other JavaScript frameworks.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class BXLoading extends HTMLElement {
  // Called when this custom element gets into render tree
  connectedCallback() {
    // "this" here is "<bx-loading>" element
    this.innerHTML =
      '(e.g. snippet from http://carbondesignsystem.com/components/loading/code)';
    this.loading = CarbonComponents.Loading.create(
      this.querySelector('[data-loading]')
    );
  }
  // Called when this custom element gets out of render tree
  disconnectedCallback() {
    this.loading.release();
  }
}
customElements.define('bx-loading', BXLoading);
`}</code></pre>
    <h3 {...{
      "id": "polyfills-for-older-browsers"
    }}>{`Polyfills for older browsers`}</h3>
    <p>{`carbon-components requires some polyfills for older browsers, in addition to carbon-components.js (or carbon-components.min.js).
The latest list of polyfills is maintained in `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-components/blob/master/packages/components/demo/polyfills/index.js"
      }}>{`carbon-components/blog/master/packages/components/demo/polyfills/index.js`}</a>{`.
You can easily find the polyfills in NPM, etc. The current list is below:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from"
        }}>{`Array.from()`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/sign"
        }}>{`Math.sign()`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign"
        }}>{`Object.assign()`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent"
        }}>{`CustomEvent`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/es/docs/Web/API/Element/closest"
        }}>{`Element#closest()`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/Element/matches"
        }}>{`Element#matches()`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/Element/classList#Methods"
        }}>{`2nd arg of Element.classList.toggle()`}</a></li>
    </ul>
    <h2 {...{
      "id": "troubleshooting"
    }}>{`Troubleshooting`}</h2>
    <p>{`If you experience any issues while getting set up with Carbon Components, please head over to the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-components"
      }}>{`GitHub repo`}</a>{` for more guidelines and support. Please `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-components/issues"
      }}>{`create an issue`}</a>{` if your issue does not already exist.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      